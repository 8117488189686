/**
 * <PRE>
 * System Name : 사랑On NEXT 시스템(TSS2-MSP)
 * Business Name : 수익증권
 * Class Name : FSAmlUtil.js
 * Description : 수익증권 공통 유틸 (AS-IS: ComAml.js)
 * </PRE>
 */

  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'


  const FSAmlUtil = {}

  let lv_edd_pep_appr_yn    = '' // EDD/PEP  결재 여부


  /************************************************************************************************
   * Function명    : gfn_aml_real_owner_check
   * 설명          : 실제소유자여부 당일진행여부 확인 (당일진행 이력이 있는 경우 aml 체크는 pass) 
   * pData         : 계좌번호/고객번호
   * alertFunc     : ALERT 함수
   * popupFunc     : 팝업 open 함수
   * 실제 소유 여부 true / false
   ************************************************************************************************/
  FSAmlUtil.gfn_aml_real_owner_check = async function(pContext, pData, alertFunc, popupFunc) {
    console.log('gfn_aml_real_owner_check...')

    if( pData ) {

      // EDD/PEP 결재 여부 초기화
      lv_edd_pep_appr_yn = ''

      const context     = !pContext ? this : pContext
      let t_eaiCommObj  = FSCommUtil.gfn_eaiCommObj()

      if( FSCommUtil.gfn_length(pData) == 13 || FSCommUtil.gfn_length(pData) == 10 ) {
        pData = FSCommUtil.gfn_substr(pData, 0, 7)
      }

      t_eaiCommObj.lv_vm = context
      t_eaiCommObj.auth = 'S'
      t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570268_S'
      t_eaiCommObj.params = {
        bfcer_accn_no : pData,
      }

      let response = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

      if( response && response.data ) {
        let resultCd = FSCommUtil.gfn_trim(response.data.rt_cd)
        let bResult = false
        console.log(resultCd)
        // resultCd = 3

        if(resultCd == 3) {
          // 당일 실제소유자여부 작성이력 없음! (팝업 띄워줘야 함!)
          bResult = false

        } else if(resultCd == 2) {
          // 당일 실제소유자여부 작성이력 있음! (팝업 띄워줄 필요 없음!)
          bResult = true

        } else if(resultCd == 4) {
          // 당일 실제소유자여부 작성이력은 있는데 & EDD/PEP 대상이면서 결재를 안받았다!
          lv_edd_pep_appr_yn = 'N'  // EDD/PEP  결재 여부
          bResult = false

        } else {
          bResult = true

        }

        if( bResult == false && resultCd == 3 ) {
          bResult = await FSAmlUtil.gfn_aml_real_owner(context, 'N', pData, alertFunc, popupFunc)
        } else {
          bResult = true
        }

        return bResult
      }

    }
  }


  /************************************************************************************************
   * Function명     : gfn_aml_check
   * 설명           : 자금세탁방지 및 테러 자금방저정책 ( AML ) 체크
   * acct_no        : 계좌번호/고객번호
   * parent_tr_cd   : 화면 ClsId
   * parent_trt_amt : 거래금액
   * alertFunc      : ALERT 함수
   * popupFunc      : 팝업 open 함수
   * CDD/EDD 수행대상 여부 true / false
   ************************************************************************************************/
  FSAmlUtil.gfn_aml_check = async function (pContext, acct_no, parent_tr_cd, parent_trt_amt, reCall_yn, reObjRtn, alertFunc, popupFunc) {
    console.log('gfn_aml_check...')

    const context = !pContext ? this : pContext

    /* ASR230200824 고객확인 관련 화면 개선 요청 (2023.04.04) 시작
    * 공통코드(878, AML_대상화면)에 등록된 TR_CD인지 확인 후 신규/지속 거래 구분 값 셋팅
    */
    let process_gb = await FSAmlUtil.gfn_aml_obj_tr_info(context, parent_tr_cd)
    let aml_busi_gb = ''
    console.log('process_gb=' + process_gb)

    if ( process_gb == '3' ) {		// 신규거래
      aml_busi_gb = 'FM01'

    } else if ( process_gb == '1' ) {		// 지속거래
      aml_busi_gb = 'FM99'

    } else {		// 정의되지 않음(종료)
      console.log('(FSAmlUtil.js) AML체크 대상 아님(종료)')
      return true
    }


    console.log("**************** aml 체크 시작!")
    console.log("**************** reCall_yn=" + reCall_yn)
    
    if(reCall_yn != undefined)
    console.log("**************** trd_amt=" + reObjRtn.trd_amt)

    let vActionval
    let vOutds
    
    let cust_no
    let trt_amt = 0
    let vCorp_gb
    let vReal_owner_gb
    let vReal_owner_nm
    let vRname_no
    let vBirth_dt
    let vCtzn_natn_cd
    let vQues_Info_cd


    if( FSCommUtil.gfn_length(acct_no) == 13 || FSCommUtil.gfn_length(acct_no) == 10 ) {
      cust_no = FSCommUtil.gfn_substr(acct_no, 0, 7)
    } else {
      cust_no = acct_no
    }


    console.log("**************** aml 1")
    let t_aml_cu116500_data = await FSAmlUtil.fn_aml_cu116500(context, cust_no)

    if( FSCommUtil.gfn_trim(t_aml_cu116500_data.cust_cnf_syst_cust_cnf_yn) != 'Y' ) {
      let t_messageData = {
        content: 'CDD정보(#1165)를 추가 입력한 후 작업하세요.'
      }
      FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
      return false
    }


    // console.log("**************** aml 2")
    // if( lv_edd_pep_appr_yn == 'N' ){ //EDD/PEP 결재를 안받았으면 진행 못해!
    //   let t_messageData = {
    //     content: 'EDD/PEP 고위험승인요청 결재 미완료 상태입니다.'
    //   }
    //   FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
    //   return false
    // }


    // console.log("**************** aml 3")
    // let oArg = {
    //   rname_no_gb: FSCommUtil.gfn_trim(t_aml_cu116500_data.bfcer_rlnm_no_knd_cd),
    // }


    // console.log("**************** aml 4")
    // if( parent_tr_cd == 'RP8282' || parent_tr_cd == 'RP2010' ) {
    //   // 재이행
    //   if( reCall_yn == 'Y' ) {
    //     process_gb = '3'
    //     aml_busi_gb	= 'FM02'
    //   } else {
    //     process_gb = '1'
    //     aml_busi_gb	= 'FM99'
    //   }

    //   trt_amt = parent_trt_amt
    // }

    // if( process_gb == '3' && aml_busi_gb == 'FM01' ) {
    //   // vue 에서 팝업 호출 문제로 AML팝업 확인시 reObjRtn 값에 AML 결과 매핑하여 재호출하도록 로직 추가
    //   if( FSCommUtil.gfn_isNull(reObjRtn.trd_amt) ) {
    //     // 고객번호 추가
    //     oArg.cust_no = cust_no

    //     popupFunc.call(context, 'MSPFS301P', oArg)
    //     return false
    //   }
    // }

    if( reCall_yn == 'Y' ) {
      process_gb = '3'
      aml_busi_gb	= 'FM02'
    } 

    console.log("**************** aml 5")
    console.log("**************** aml 5 process_gb=" + process_gb)
    console.log("**************** aml 5 aml_busi_gb=" + aml_busi_gb)

    // 실제소유자 설문 여부 값 세팅
    // if(process_gb == "3" && aml_busi_gb	== "FM01") {
    //   let objRtn

	  //   if( !FSCommUtil.gfn_isNull( reObjRtn ) ) {
    //     objRtn = reObjRtn
    //   }

    //   if( FSCommUtil.gfn_isNull( objRtn ) ) {
    //     return false

    //   } else {

    //     trt_amt          = FSCommUtil.gfn_trim(objRtn.trd_amt)
    //     vCorp_gb         = FSCommUtil.gfn_trim(objRtn.corp_gb)
    //     vReal_owner_gb	 = FSCommUtil.gfn_trim(objRtn.real_owner_gb)
    //     vReal_owner_nm   = FSCommUtil.gfn_trim(objRtn.real_owner_nm)
    //     vRname_no        = FSCommUtil.gfn_trim(objRtn.rname_no)
    //     vBirth_dt        = FSCommUtil.gfn_trim(objRtn.birth_dt)
    //     vCtzn_natn_cd    = FSCommUtil.gfn_trim(objRtn.ctzn_natn_cd)
    //     vQues_Info_cd    = FSCommUtil.gfn_trim(objRtn.ques_info_cd)

    //     if( FSCommUtil.gfn_isNull(trt_amt) ) {
    //       return false
    //     }
        
    //     if( FSCommUtil.gfn_isNull(vReal_owner_gb) ) {
    //       return false
    //     }

    //   }
    // }


    // 로그인정보
    let t_basInfo = context.getStore('fsStore').getters.getBasInfo.data
    let usid = ''
    let ipAddr = ''    
    if( FSCommUtil.gfn_isNull(t_basInfo) == true ) {
      console.log('로그인정보없음')
      // return false
    } else {
      usid = t_basInfo.usid
      ipAddr = t_basInfo.ipAddr
    }

    
    // AML(CDD/EDD)실제소유자확인
    let t_aml_cu170000_data = await FSAmlUtil.gfn_aml_cu170000_custinfo(context, {
			cust_no: cust_no,
      acct_no: cust_no,	

      process_gb: process_gb,
      aml_busi_gb: aml_busi_gb,

      trt_amt_1: reObjRtn.trd_amt,
      corp_gb: reObjRtn.corp_gb,
      real_owner_gb: reObjRtn.real_owner_gb,
      real_owner_nm: reObjRtn.real_owner_nm,
      real_owner_rname_no: reObjRtn.rname_no,
      real_owner_birth_dt: reObjRtn.birth_dt,
      real_owner_natn_cd: reObjRtn.ctzn_natn_cd,
      real_owner_ques_info_cd: reObjRtn.ques_info_cd,

      insr_indcl_cd: FSCommUtil.gfn_trim(t_aml_cu116500_data.bfcer_indcl_cd), // CDD고객기본의 수익증권업종코드
      lis_yn: FSCommUtil.gfn_trim(t_aml_cu116500_data.lstck_yn),              // CDD고객기본의 상장여부
      parent_tr_cd: parent_tr_cd,

      emp_id: usid,
      term_ip: ipAddr
	  })

    console.log('========= AML(CDD/EDD)실제소유자확인 데이터 =========')
    console.log(t_aml_cu170000_data)
    console.log('========= AML(CDD/EDD)실제소유자확인 데이터 =========')

    let rt_cd = t_aml_cu170000_data.rt_cd
    console.log('rt_cd= ' + rt_cd)
    // rt_cd = 'W'

    if( rt_cd == 'W' ) {
      await FSAmlUtil.gfn_aml_real_owner(context, 'Y', cust_no, alertFunc, popupFunc, acct_no)
      return false
      
    } else if( rt_cd == 'X' || rt_cd == 'D' ) {

      let msg_txt = t_aml_cu170000_data.msg_txt
      console.log("AML : " + msg_txt)

      let t_messageData = {
        content: msg_txt
      }

      if(FSCommUtil.gfn_trim(msg_txt) == "보험직업코드를 확인하세요." || FSCommUtil.gfn_trim(msg_txt) == "보험업종코드를 확인하세요.") {

        let t_rname_no_gb = t_aml_cu170000_data.rname_no_gb
        let t_reg_no      = t_aml_cu170000_data.reg_no
        
        // console.log('스크린ID확인')
        // console.log(context.$options.screenId)
        t_messageData = {
          content: 'AML : ' + msg_txt,          
          cancel: false,
          confirm: true,
          // confirmFunc: function () { // 고객요청으로 기능 삭제
          //   this.$router.push({
          //     name: 'MSPFS100M', 
          //     params: {
          //       rmvl_srn_id: parent_tr_cd,
          //       cob_rname_gb: t_rname_no_gb,
          //       ed_rname_no: [FSCommUtil.gfn_substr(t_reg_no, 0, 6), FSCommUtil.gfn_substr(t_reg_no, 6, 7)],
          //       call_screen_id : context.$options.screenId,
          //       lv_ed_group_no: context.$options.screenId == 'MSPFS307M' ? context.$data.lv_ed_group_no.value : '', // MSPFS307M(계좌신규)일 경우 다시 라우터를 통해 돌아와서 그룹번호를 자동 input 해줄값
          //     }
          //   })
          // },
        }

      }

      FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
      return false
    }

    /* 
    1 : 거래불가
    2 : EDD+PEP 수행
    3 : EDD 수행
    4 : EDD 수행
    5 : CDD 수행(NEOS는 이미 한 상태이겠죠)
    6 : CDD 수행(NEOS는 이미 한 상태이겠죠)
    9 : RISK-EVAL-CD='H' 일때 EDD 수행
    */

    let result = false
    let t_messageData = {}
    let wl_act_rt = t_aml_cu170000_data.wl_act_rt
    console.log('wl_act_rt= ' + wl_act_rt)
    // wl_act_rt = '2'

    // 팝업에서 필요하여 데이터 추가
    let aml_refr_dtm  = t_aml_cu170000_data.aml_refr_dtm
    let ref_no        = t_aml_cu170000_data.ref_no


    // 신규 거래 //#1801 판매사이동(계좌확인서등록) 화면도 추가
    if( process_gb == '3' ) {
      switch(wl_act_rt) {

        case "1" :
          t_messageData = {
            content: '해당고객은 금융거래제한자입니다.\n당사와 거래 불가하며 자세한 사항은 준법감시P로 문의바랍니다.'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break

        case "2" :
          t_messageData = {
            cancel: false,
            confirm: true,
            confirmFunc: function() {
              FSAmlUtil.fn_pop_aml_request(context, "PEP", acct_no, aml_busi_gb, parent_tr_cd, reObjRtn.trd_amt, aml_refr_dtm, ref_no, popupFunc)
            },
            content: '해당고객은 정치인(PEP)고객입니다.\n아래의 고객정보를 추가로 확보하시기 바랍니다.\n■ 거래목적, 자금출처, 자산규모 等\n■ 고객확인서(개인EDD) 별지 추가 작성'
          }          
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break

        case "3" :

        case "4" :
          t_messageData = {
            cancel: false,
            confirm: true,
            confirmFunc: function() {
              FSAmlUtil.fn_pop_aml_request(context, "EDD", acct_no, aml_busi_gb, parent_tr_cd, reObjRtn.trd_amt, aml_refr_dtm, ref_no, popupFunc)
            },
            content: '해당고객은 강화된 고객주의의무(EDD)대상입니다.\n아래의 고객정보를 추가로 확보하시기 바랍니다.\n■ 거래목적, 자금출처, 자산규모 等'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break

        // 이런 경우는 안생긴다. CDD 를 모두 입력해서 진행하기 때문
        case "5" :

        case "6" :
          result = true
          break

        case "7" :
          t_messageData = {
            content: '가상자산사업자는 수익증권 거래 불가합니다.'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break          

        case "9" :
          if( t_aml_cu170000_data.risk_eval_gd == "H" ) {
            t_messageData = {
              cancel: false,
              confirm: true,
              confirmFunc: function() {
                FSAmlUtil.fn_pop_aml_request(context, "EDD", acct_no, aml_busi_gb, parent_tr_cd, reObjRtn.trd_amt, aml_refr_dtm, ref_no, popupFunc)
              },
              content: '해당고객은 강화된 고객주의의무(EDD)대상입니다.\n아래의 고객정보를 추가로 확보하시기 바랍니다.\n■ 거래목적, 자금출처, 자산규모 等'
            }
            FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
            result = false
            break

          } else {

            result = true
            break
          }

        case "8" :    // PDS1410 테이블에 등록되고 책임자 승인이 되지 않은 경우
          t_messageData = {
            content: '고위험승인요청 결재 후 진행 하시기 바랍니다.'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break

        case '0' :   // PDS1410 테이블에 기등록 되었으므로 통과
          result = true
          break

      }
    }
    
    // 지속 거래
    else if( process_gb == '1' ) {
      switch(wl_act_rt) {
        case "1" :
          t_messageData = {
            content: '해당고객은 금융거래제한자입니다.\n당사와 거래 불가하며 자세한 사항은 준법감시P로 문의바랍니다.'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)          
          result = false
          break

        case "2" :
          t_messageData = {
            content: '해당고객은 정치인(PEP)고객입니다(결재 불필요).'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = true
          break

        case "7" :
          t_messageData = {
            content: '가상자산사업자는 수익증권 거래 불가합니다.'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break

        case "3" :

        case "4" :
          t_messageData = {
            content: '해당고객은 강화된 고객주의의무(EDD)대상입니다(결재 불필요).'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = true
          break

        case "8" :
          t_messageData = {
            content: '고위험승인요청 결재 후 진행 하시기 바랍니다.'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break
        // 이런 경우는 안생긴다. CDD 를 모두 입력해서 진행하기 때문

        case "5" :

        case "6" :
          result = true
          break

        case "9" :
          if( t_aml_cu170000_data.risk_eval_gd == "H" ) {
            t_messageData = {
              content: '해당고객은 강화된 고객주의의무(EDD)대상입니다(결재 불필요).'
            }
            FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
            result = true
            break

          } else {
            result = true
            break
          }

        case "0" :   // TFOC01410 테이블에 기등록 되었으므로 통과
          result = true
          break
          
      }
    }

    // 사후 관리
    else {
      switch(wl_act_rt) {
        case "1" :
          t_messageData = {
            content: '해당고객은 금융거래제한자입니다.\n당사와 거래 불가하며 자세한 사항은 준법감시P로 문의바랍니다.'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break

        case "2" :
          t_messageData = {
            cancel: false,
            confirm: true,
            confirmFunc: function() {
              FSAmlUtil.fn_pop_aml_request(context, "PEP", acct_no, aml_busi_gb, parent_tr_cd, reObjRtn.trd_amt, aml_refr_dtm, ref_no, popupFunc)
            },
            content: '해당고객은 정치인(PEP)고객입니다.\n아래의 고객정보를 추가로 확보하시기 바랍니다.\n■ 거래목적, 자금출처, 자산규모 等\n■ 고객확인서(개인EDD) 별지 추가 작성'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break

        case "3" :

        case "4" :
          t_messageData = {
            cancel: false,
            confirm: true,
            confirmFunc: function() {
              FSAmlUtil.fn_pop_aml_request(context, "EDD", acct_no, aml_busi_gb, parent_tr_cd, reObjRtn.trd_amt, aml_refr_dtm, ref_no, popupFunc)
            },
            content: '해당고객은 강화된 고객주의의무(EDD)대상입니다.\n아래의 고객정보를 추가로 확보하시기 바랍니다.\n■ 거래목적, 자금출처, 자산규모 等'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break

        // 이런 경우는 안생긴다. CDD 를 모두 입력해서 진행하기 때문
        case "5" :

        case "6" :
    
          result = true
          break
        case "9" :
          if( t_aml_cu170000_data.risk_eval_gd == "H" ) {
            t_messageData = {
              cancel: false,
              confirm: true,
              confirmFunc: function() {
                FSAmlUtil.fn_pop_aml_request(context, "EDD", acct_no, aml_busi_gb, parent_tr_cd, reObjRtn.trd_amt, aml_refr_dtm, ref_no, popupFunc)                
              },
              content: '해당고객은 강화된 고객주의의무(EDD)대상입니다.\n아래의 고객정보를 추가로 확보하시기 바랍니다.\n■ 거래목적, 자금출처, 자산규모 等'
            }
            FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
            result = false
            break

          } else {
            result = true
            break
          }

        case "8" :    // TFOC01410 테이블에 등록되고 책임자 승인이 되지 않은 경우
          t_messageData = {
            content: '고위험승인요청 결재 후 진행 하시기 바랍니다.'
          }
          FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
          result = false
          break

        case "0" :   // TFOC01410 테이블에 기등록 되었으므로 통과
          result = true
          break
      }
    }

    return result
  }
  

  /************************************************************************************************
   * Function명    : fn_aml_cu116500
   * 설명          : CDD 고객 정보 조회
   * pData         : 계좌번호/고객번호
   * alertFunc     : ALERT 함수
   * CDD 등록여부 true / false
   ************************************************************************************************/
  FSAmlUtil.fn_aml_cu116500 = async function(pContext, pData) {
    console.log('fn_aml_cu116500...')

    if( pData ) {

      const context     = !pContext ? this : pContext
      let t_eaiCommObj  = FSCommUtil.gfn_eaiCommObj()

      t_eaiCommObj.lv_vm = context
      t_eaiCommObj.auth = 'S'
      t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570117_S'
      t_eaiCommObj.params = {
        bfcer_accn_no     : pData
      }

      let response = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

      if( response && response.data ) {
        return response.data
      }

    }
  }


  /************************************************************************************************
   * Function명    : gfn_aml_cu170000_custinfo
   * 설명          : AML(CDD/EDD)실제소유자확인 (진행시 당일진행여부 확인 pass)
   * pData         : 실소유자 조회 정보
   ************************************************************************************************/
  FSAmlUtil.gfn_aml_cu170000_custinfo = async function(pContext, pData) {
    console.log('gfn_aml_cu170000_custinfo...')
    console.log(pData)

    if( pData ) {

      const context     = !pContext ? this : pContext
      let t_eaiCommObj  = FSCommUtil.gfn_eaiCommObj()

      t_eaiCommObj.lv_vm = context
      t_eaiCommObj.auth = 'S'
      t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570270_S'
      t_eaiCommObj.params = {
        bfcer_cust_no           : pData.cust_no,
        bfcer_accn_no           : pData.acct_no,

        process_gb              : pData.process_gb,
        aml_busi_gb             : pData.aml_busi_gb,

        trt_amt_1               : pData.trt_amt_1,
        corp_gb                 : pData.corp_gb,
        real_owner_gb           : pData.real_owner_gb,
        real_owner_nm           : pData.real_owner_nm,
        real_owner_rname_no     : pData.real_owner_rname_no,
        real_owner_birth_dt     : pData.real_owner_birth_dt,
        real_owner_natn_cd      : pData.real_owner_natn_cd,
        real_owner_ques_info_cd : pData.real_owner_ques_info_cd,

        insr_indcl_cd           : pData.insr_indcl_cd,
        lis_yn                  : pData.lis_yn,
        parent_tr_cd            : pData.parent_tr_cd,

        bfcer_staf_no           : pData.emp_id,
        term_ip                 : pData.term_ip,
      }

      let response = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

      if( response && response.data ) {
        let t_data = response.data.eaf10670028_I_VO

        if( FSCommUtil.gfn_length(t_data) > 0 ) {
          return t_data[0]
        }
      }

    }
  }


  /************************************************************************************************
   * Function명    : fn_pop_aml_request
   * 설명          : 고위험승인요청 팝업 띄우기
   * call_tp       : 호출타입 (EDD, PEP)
   * acct_no       : AML 업무 구분
   * aml_busi_gb   : 계좌번호
   * parent_tr_cd  : 화면 ClsId
   * trt_amt       : 거래금액
   * aml_refr_dtm  : AML참조일시(추가)
   ************************************************************************************************/
  FSAmlUtil.fn_pop_aml_request = async function(pContext, call_tp, acct_no, aml_busi_gb, parent_tr_cd, trt_amt, aml_refr_dtm, ref_no, popupFunc) {

    const context     = !pContext ? this : pContext

    let t_data = {
      call_tp: call_tp,
      acct_no: acct_no, 
      aml_busi_gb: aml_busi_gb, 
      parent_tr_cd: parent_tr_cd, 
      trt_amt: FSCommUtil.gfn_isNull(trt_amt) ? 0 : trt_amt,
      aml_refr_dtm: aml_refr_dtm, // AML참조일시(추가)
      ref_no: ref_no,             // 추가

    }
    popupFunc.call(context, 'MSPFS305P', t_data)
  }


  /************************************************************************************************
   * Function명    : gfn_aml_obj_tr_info
   * 설명          : AML 대상 화면 조회
   * parent_tr_cd  : 화면 ClsId
   ************************************************************************************************/
  FSAmlUtil.gfn_aml_obj_tr_info = async function(pContext, parent_tr_cd) {
    console.log('gfn_aml_obj_tr_info...')
    
    const context     = !pContext ? this : pContext
    let t_eaiCommObj  = FSCommUtil.gfn_eaiCommObj()

    t_eaiCommObj.lv_vm = context
    t_eaiCommObj.auth = 'S'
    t_eaiCommObj.commHeaderVO.eaiId = 'C391_F10570288_S'
    t_eaiCommObj.params = {
      parent_tr_cd  : FSCommUtil.gfn_substr(parent_tr_cd, 2, 4)
    }

    let t_result = await FSServiceUtil.invokeInDirect(t_eaiCommObj)

    return t_result.data.comm_busn_dtl_sc_cd01
  }


  /************************************************************************************************
   * Function명    : gfn_aml_real_owner
   * 설명          : AML 및 고객확인 체크 요건 보완 (2023.06.20)
   ************************************************************************************************/
  FSAmlUtil.gfn_aml_real_owner = async function(pContext, reCall_Yn, cust_no, alertFunc, popupFunc, acct_no) {
    console.log('gfn_aml_real_owner...')
    
    const context = !pContext ? this : pContext


    // CDD정보 체크
    let gds_common_aml_cust = await FSAmlUtil.fn_aml_cu116500(context, cust_no)
    if( FSCommUtil.gfn_trim(gds_common_aml_cust.cust_cnf_syst_cust_cnf_yn) != 'Y' ) {
      let t_messageData = {
        content: 'CDD정보(#1165)를 추가 입력한 후 작업하세요.'
      }
      FSCommUtil.gfn_commMessage(context, t_messageData, alertFunc)
      return false
    }

    // reCall_yn 값에 따라 팝업 띄움
    if ( reCall_Yn == 'N' ) {
      // 고객번호 추가
      let oArg = {
        cust_no: cust_no,
      }
      popupFunc.call(context, 'MSPFS301P', oArg)
      return false

    } else if ( reCall_Yn == 'Y' ) {
      // 고객번호/AML 재이행 옵션값 추가
      let oArg = {
        cust_no: cust_no,
        acct_no: acct_no,
        re_aml_flag: 'Y',
      }
      popupFunc.call(context, 'MSPFS315P', oArg)
      return false

    }
    return true
  }



  export default FSAmlUtil
  // eslint-disable-next-line
